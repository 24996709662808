$theme-colors: (
  "primary": #054D6E,
  "primary_2": #0091D1,
  "secondary": #5577ff,
  "bg": #091832,
  "secondary-bg": #214376,
  "dark-bg": #1b2c45,
  "white": #fff,
  "info": #17a2b8,
  "warning": #ffc107,
  "gray": #ced4da,
  "danger": red,
);

@import "~bootstrap/scss/bootstrap";
@import url('https://fonts.googleapis.com/css2?family=Ubuntu&display=swap');

body,
*,
html {
  font-family: 'Ubuntu', sans-serif;


}


.fade-in {
  opacity: 0;
  /* Set the initial opacity to 0 */
  animation: fadeIn 1s ease-in-out forwards;
  /* Apply the fade-in animation */
}

@keyframes fadeIn {
  0% {
    opacity: 0;
    /* Start with an opacity of 0 */
  }

  100% {
    opacity: 1;
    /* End with an opacity of 1 */
  }
}


.back-to-top-button {
  position: fixed;
  bottom: 20px;
  right: 20px;
  background-color: #054D6E;
  /* Customize the button background color */
  color: #fff;
  /* Customize the button text color */

  border: none;
  border-radius: 50%;
  cursor: pointer;
  padding: 0;
  margin: 0;
  transition: background-color 0.3s ease-in-out;

}

.back-to-top-button img {
  height: 50px;
  width: 50px;

}

.back-to-top-button:hover {
  background-color: #0056b3;
  /* Change the background color on hover */
}