.card-footer 
{
  border: none;
}

.text-underline-none
{
  text-decoration: none !important;
}

.card-footer{
  border: none;
  background-color:#1b2c45 ;
  
}


.link-text{
  opacity: 1;
  transition: all 0.3s;
  
}

.link-text:hover{
 
  opacity: 0.8;
  transition: all 0.3s;
  
}