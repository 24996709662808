
.cover-image 
{
    width: 100%;
    object-fit: cover;
    height: 500px;
}

.people-card  img 
{
    border-radius: 100%;
    height: 60px;
    width: 60px;
    object-fit: cover;
}

.col-logo
{
    height: 60px;
}

.h4{
    max-width: 95vw;
}

@media only screen and (max-width: 600px) {

    .cover-image 
{
    width: 100%;
    object-fit: cover;
    height: 300px;
}
}