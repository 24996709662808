.profile img 
{
    border-radius: 100%;
    width: 150px;
    height: 150px;
    object-fit: cover;
}


.profile {
    display: flex;
    flex-direction: column;
}