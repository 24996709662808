.card-footer {
  border: none;
}

.text-underline-none {
  text-decoration: none !important;
}

.card-footer {
  border: none;
  background-color: #1b2c45;
}

.card-footer p {
  font-size: 1.1em;
}

.card-footer p:hover {
  color: red;
}

.multiline-badge {
  white-space: pre-wrap;
  word-wrap: break-word;
}
