.main-image {
  width: 40%;
}

.home-hero {
  min-height: fit-content;
}

.message-box {
  border-radius: 10px;
  height: 8vw;
}


.send-btn {
  background-color: #09658E;
  color: #fff;
  border: none;
}
.send-btn:hover {
  background-color: #1b7399;
  color: #fff;
  border: none;
}
.send-btn:disabled {
  background-color: #979eee;
  color: #fff;
  border: none;
}

.down-btn
{
  transition: all 0.5s;
}

.down-btn:hover{
  transition: all 0.5s;
  
  transform: translateY(5px);
 
}

button , .btn {
  font-size: 0.95em;
}

.research-area-card
{
  width: 20%;
}


@media only screen and (max-width: 768px) {
  .research-area-card
{
  width: 100%;
}

  .main-image {
    width: 80%;
  }

  .home-hero {
    height: fit-content;
  }

  .message-box {
    border-radius: 10px;
    height: 30vw;
  }
}

